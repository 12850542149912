import React from "react";
import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";

import MainNavigation from "./shared/components/Navigation/MainNavigation";
import NewTag from "./tags/pages/NewTags";
import Tags from "./tags/pages/Tags";
import Users from "./users/pages/Users";
import NewUser from "./users/pages/NewUser";
import Routes from "./routes/pages/Routes";
import NewRoute from "./routes/pages/NewRoute";

import UserLogin from "./users/pages/UserLogin";
import {AuthContext} from "./shared/context/auth-context";
import {useAuth} from "./shared/hooks/auth-hook";

const App = () => {
  const {token, login, logout, userId, canEditDelete, canInsert, isSuperAdmin} = useAuth();
  console.log("API URI:" + process.env.REACT_APP_SRV_URL);
  let routes;
  if (token) {
    routes = (
      <Switch>
        <Route path="/tags/new" exact>
          <NewTag />
        </Route>
        <Route path="/tags" exact>
          <Tags />
        </Route>
        <Route path="/users" exact>
          <Users />
        </Route>
        <Route path="/users/new" exact>
          <NewUser />
        </Route>
        <Route path="/routes" exact>
          <Routes />
        </Route>
        <Route path="/routes/new" exact>
          <NewRoute />
        </Route>
        <Redirect to="/routes" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <h1>Register hompage!</h1>
        </Route>
        <Route path="/tags" exact>
          <Tags />
        </Route>
        <Route path="/auth">
          <UserLogin />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
        canEditDelete: canEditDelete,
        canInsert: canInsert,
        isSuperAdmin: isSuperAdmin,
      }}>
      <Router>
        <MainNavigation />
        <main>{routes}</main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
