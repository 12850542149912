import {useState, useCallback, useRef, useEffect} from "react";

export const useHttpClient = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(async (url, method = "GET", body = null, headers = {}) => {
    setIsSubmitting(true);
    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);

    try {
      const response = await fetch(url, {
        method,
        body,
        headers,
        signal: httpAbortCtrl.signal,
      });

      const responseData = await response.json();

      activeHttpRequests.current = activeHttpRequests.current.filter((reqCtrl) => reqCtrl !== httpAbortCtrl);

      if (!response.ok) {
        throw new Error(responseData.error);
      }

      setIsSubmitting(false);
      return responseData;
    } catch (err) {
      setError(err.message + "\n" + err);
      setIsSubmitting(false);
      throw err;
    }
  }, []);

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return {isSubmitting, error, sendRequest, clearError};
};
