import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import "./Tag.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const Tag = ({onDelete, label, id, color}) => {
  const classes = useStyles();
  return <Chip key={id} label={label} onDelete={onDelete} style={{backgroundColor: color}} className="tag" />;
};

export default Tag;
