import React, {useEffect, useState, useContext} from "react";
import {AuthContext} from "../../shared/context/auth-context";
import {useHttpClient} from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Card from "../../shared/components/UIElements/Card";
import Button from "../../shared/components/FormElements/Button";
import Modal from "../../shared/components/UIElements/Modal";
import RoutesTable from "../components/RoutesTable";

import "./Routes.css";
const {REQUEST_TAG_TYPE, RESPONSE_TAG_TYPE} = require("../../shared/util/Constants");
let ALL_TAGS = [];

const Routes = () => {
  const {isSubmitting, error, sendRequest, clearError} = useHttpClient();
  const auth = useContext(AuthContext);

  const [data, setData] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [row, setRow] = useState("");
  const [reload, setReload] = useState(1);
  const showDeleteWarningHandler = (row) => {
    setShowConfirmModal(true);
    setRow(row);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  const confirmDeleteHandler = async () => {
    console.log("DELETE " + row);
    try {
      await sendRequest(`${process.env.REACT_APP_SRV_URL}/register/routes/${row}`, "DELETE", null, {
        "Content-Type": "application/json",
        "x-api-token": auth.token,
      });
      setShowConfirmModal(false);
      setReload(() => {
        return reload + 1;
      });
    } catch (err) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth.isLoggedIn) {
          ALL_TAGS = await sendRequest(process.env.REACT_APP_SRV_URL + "/register/routes/tags", "GET", null, {
            "x-api-token": auth.token,
          });

          const responseData = await sendRequest(process.env.REACT_APP_SRV_URL + "/register/routes", "GET", null, {
            "x-api-token": auth.token,
          });
          for (let i = 0; i < responseData.length; i++) {
            responseData[i].TAGS = [];
            const resTags = await sendRequest(
              `${process.env.REACT_APP_SRV_URL}/register/routes/${responseData[i].routeID}/response/tags`,
              "GET",
              null,
              {
                "x-api-token": auth.token,
              }
            );
            responseData[i].TAGS[RESPONSE_TAG_TYPE] = resTags;
            const reqTags = await sendRequest(
              `${process.env.REACT_APP_SRV_URL}/register/routes/${responseData[i].routeID}/request/tags`,
              "GET",
              null,
              {
                "x-api-token": auth.token,
              }
            );
            responseData[i].TAGS[REQUEST_TAG_TYPE] = reqTags;
          }
          setData(responseData);
        } else {
          console.log("Token not set");
        }
      } catch (err) {}
    };
    fetchData();
  }, [auth.token]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Are you sure?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              CANCEL
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              DELETE
            </Button>
          </React.Fragment>
        }>
        <p>
          Do you want to proceed and delete <b>{row}</b>?
        </p>
      </Modal>

      {auth.isLoggedIn && (
        <React.Fragment>
          <Card>
            <Button disabled={!auth.canInsert()} to="/routes/new">
              NEW SERVICE/ROUTE
            </Button>
          </Card>
          )
          <Card>
            {isSubmitting && (
              <div className="center">
                <LoadingSpinner />
              </div>
            )}
            {!isSubmitting && data && (
              <React.Fragment>
                <RoutesTable
                  data={data}
                  showDeleteWarningHandler={showDeleteWarningHandler}
                  setData={setData}
                  sendRequest={sendRequest}
                  setShowConfirmModal={setShowConfirmModal}
                  setReload={setReload}
                  reload={reload}
                  ALL_TAGS={ALL_TAGS}
                />
              </React.Fragment>
            )}
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default Routes;
