import React, {useContext} from "react";
import {NavLink, useHistory} from "react-router-dom";

import {AuthContext} from "../../context/auth-context";

import "./NavLinks.css";

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const logoutAndGoHome = () => {
    auth.logout();
    history.push("/");
  };
  return (
    <ul className="nav-links">
      {auth.isLoggedIn && (
        <li>
          <NavLink to={`/routes`}>SERVICES/ROUTES</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/tags">TAGS</NavLink>
        </li>
      )}

      {auth.isLoggedIn && (
        <li>
          <NavLink to="/users">USERS</NavLink>
        </li>
      )}
      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/auth">AUTHENTICATE</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <button onClick={logoutAndGoHome}>LOGOUT {auth.userId}</button>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
