import React, {useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useHttpClient} from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Card from "../../shared/components/UIElements/Card";
import * as Yup from "yup";
import {withFormik} from "formik";

import {DisplayFormikState, TextInput} from "../../shared/components/FormikElements/FormikElements";

import "./NewUser.css";

import {AuthContext} from "../../shared/context/auth-context";
import Button from "../../shared/components/FormElements/Button";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    userID: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .matches(/^[a-zA-Z0-9]+$/, "Can only contain simple characters without spaces.")
      .required("Required"),
    firstName: Yup.string().min(2).max(300),
    lastName: Yup.string().min(2).max(300),
    email: Yup.string().email(),
    role: Yup.string(),
    password1: Yup.string(),
    password2: Yup.string(),
  }),

  mapPropsToValues: ({data}) => ({
    ...data,
  }),
  handleSubmit: async (payload, {setSubmitting, resetForm, props}) => {
    try {
      if (props.insert) {
        payload.password = payload.password1;
      } else {
        payload.newpassword = payload.password1;
        delete payload.password;
      }
      delete payload.password1;
      delete payload.password2;
      console.log("insert:" + props.insert + " " + JSON.stringify(payload));
      let result = await props.sendRequest(
        props.insert ? `${process.env.REACT_APP_SRV_URL}/register/users` : `${process.env.REACT_APP_SRV_URL}/register/users/${payload.id}`,
        props.insert ? "POST" : "PUT",
        JSON.stringify(payload),
        {
          "Content-Type": "application/json",
          "x-api-token": props.auth.token,
        }
      );
      resetForm();

      props.history.push("/users");
    } catch (err) {
      console.log("My ERROR " + err);
    }
  },
  displayName: "MyForm UserID",
});

const MyForm = (props) => {
  const {values, touched, errors, dirty, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, insert} = props;
  const auth = useContext(AuthContext);
  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <LoadingSpinner asOverlay />}

      <TextInput
        id="userID"
        type="text"
        label="*UserID"
        placeholder="Without spaces and special characters."
        error={touched.userID && errors.userID}
        value={values.userID}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={!insert}
      />

      <TextInput
        id="firstName"
        label="*FirstName"
        placeholder="Janez"
        error={touched.firstName && errors.firstName}
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="lastName"
        label="*Lastname"
        placeholder="Novak"
        error={touched.lastName && errors.lastName}
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="email"
        type="email"
        label="Email"
        placeholder="some@mail.si "
        error={touched.email && errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="password1"
        type="password"
        label="Password"
        error={touched.password1 && errors.password1}
        value={values.password1}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="password2"
        type="password"
        label="Confirm password"
        error={touched.password2 && errors.password2}
        value={values.password2}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <TextInput
        id="role"
        type="role"
        label="Role"
        placeholder="user"
        error={touched.role && errors.role}
        value={values.role}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={!auth.isSuperAdmin()}
      />

      <Button type="button" className="outline" onClick={handleReset} insert={insert} disabled={!dirty || isSubmitting}>
        Reset
      </Button>
      <Button type="submit" disabled={isSubmitting}>
        Submit
      </Button>
      <Button
        type="button"
        className="outline"
        onClick={() => {
          props.history.push("/users");
        }}>
        Cancel
      </Button>
      <DisplayFormikState {...props} />
    </form>
  );
};

const MyUserForm = formikEnhancer(MyForm);

const NewUser = () => {
  console.log("New User open");
  const {error, sendRequest, clearError} = useHttpClient();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  let data;
  let insert;
  if (!location.state) console.log("No location state");
  if (location.state && location.state.data) {
    data = location.state.data;
    data.password1 = "";
    data.password2 = "";
    insert = false;
  } else {
    insert = true;
    data = {
      userID: "",
      password1: "",
      password2: "",
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    };
  }
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Card>
        <div>
          <h1 className="center">{insert ? "New user" : "Update user"}</h1>
          <MyUserForm data={data} sendRequest={sendRequest} auth={auth} history={history} insert={insert} />
        </div>
      </Card>
    </React.Fragment>
  );
};
export default NewUser;
