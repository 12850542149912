import React, {useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useHttpClient} from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Card from "../../shared/components/UIElements/Card";
import * as Yup from "yup";
import {withFormik} from "formik";

import {DisplayFormikState, TextInput, TextAreaInput} from "../../shared/components/FormikElements/FormikElements";

import "./NewTags.css";

import {AuthContext} from "../../shared/context/auth-context";
import Button from "../../shared/components/FormElements/Button";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    tagID: Yup.string()
      .min(2, "Too Short!")
      .max(14, "Too Long!")
      .matches(/^[a-zA-Z0-9]+$/, "Tag can only contain simple characters without spaces.")
      .required("Required"),
    description: Yup.string().required().min(2).max(300),
    link: Yup.string().url(),
  }),

  mapPropsToValues: ({data}) => ({
    ...data,
  }),
  handleSubmit: async (payload, {setSubmitting, resetForm, props}) => {
    try {
      console.log("insert:" + props.insert + " " + JSON.stringify(payload));
      let result = await props.sendRequest(
        props.insert
          ? `${process.env.REACT_APP_SRV_URL}/register/routes/tags`
          : `${process.env.REACT_APP_SRV_URL}/register/routes/tags/${payload.id}`,
        props.insert ? "POST" : "PUT",
        JSON.stringify(payload),
        {
          "Content-Type": "application/json",
          "x-api-token": props.auth.token,
        }
      );
      resetForm();

      props.history.push("/tags");
    } catch (err) {
      console.log("My ERROR " + err + " 33" + err.message);
    }
  },
  displayName: "MyForm TagID",
});
const isDev = () => {
  return process.env.NODE_ENV === "development";
};
const MyForm = (props) => {
  const {values, touched, errors, dirty, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, insert} = props;
  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <LoadingSpinner asOverlay />}

      <TextInput
        id="tagID"
        type="text"
        label="*TagID"
        placeholder="Without spaces and special characters."
        error={touched.tagID && errors.tagID}
        value={values.tagID}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={!insert}
      />

      <TextAreaInput
        id="description"
        label="*Description"
        placeholder="some description"
        rows={4}
        error={touched.description && errors.description}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="link"
        type="text"
        label="Link"
        placeholder="For any additional documentation. "
        error={touched.link && errors.link}
        value={values.link}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Button type="button" className="outline" onClick={handleReset} insert={insert} disabled={!dirty || isSubmitting}>
        Reset
      </Button>
      <Button type="submit" disabled={isSubmitting}>
        Submit
      </Button>
      <Button
        type="button"
        className="outline"
        onClick={() => {
          props.history.push("/tags");
        }}>
        Cancel
      </Button>

      <DisplayFormikState {...props} />
    </form>
  );
};

const MyTagForm = formikEnhancer(MyForm);

const NewTag = () => {
  const {error, sendRequest, clearError} = useHttpClient();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  let data;
  let insert;
  if (!location.state) console.log("No location state");
  if (location.state && location.state.data) {
    data = location.state.data;
    insert = false;
  } else {
    insert = true;
    data = {
      tagID: "",
      description: "",
      link: "",
    };
  }
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Card>
        <div>
          <h1 className="center">{insert ? "New tag" : "Update tag"}</h1>
          <MyTagForm data={data} sendRequest={sendRequest} auth={auth} history={history} insert={insert} />
        </div>
      </Card>
    </React.Fragment>
  );
};
export default NewTag;
