import "./FormikElements.css";
import React, {useState} from "react";
import classnames from "classnames";
import {isDev} from "../../util/Util";
import {Field, FieldArray} from "formik";
import MuiTextField from "@material-ui/core/TextField";
import {Autocomplete, AutocompleteRenderInputParams} from "formik-material-ui-lab";

const InputFeedback = ({error}) => (error ? <div className="input-feedback">{error}</div> : null);

const Label = ({error, className, children, ...props}) => {
  return (
    <label className="label" {...props}>
      {children}
    </label>
  );
};

export const TextInput = ({type, id, label, error, value, onChange, className, readOnly = false, ...props}) => {
  const classes = classnames(
    "input-group",
    {
      "animated shake error": !!error,
    },
    className
  );
  console.log("readonly", readOnly);
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input id={id} className="text-input" type={type} readOnly={readOnly} value={value} onChange={onChange} {...props} />
      <InputFeedback error={error} />
    </div>
  );
};

export const TextAreaInput = ({type, id, label, error, value, onChange, className, ...props}) => {
  const classes = classnames(
    "input-group",
    {
      "animated shake error": !!error,
    },
    className
  );
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <textarea id={id} className="text-input" type={type} value={value} onChange={onChange} {...props} />
      <InputFeedback error={error} />
    </div>
  );
};

export const SelectInput = ({options, type, id, label, error, value, onChange, className, ...props}) => {
  const classes = classnames(
    "input-group",
    {
      "animated shake error": !!error,
    },
    className
  );
  //console.log("ARRAY:" + options);
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <select id={id} className="text-input" type={type} value={value} onChange={onChange} {...props}>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
      <InputFeedback error={error} />
    </div>
  );
};

export const DisplayFormikState = (props) =>
  isDev() && (
    <div style={{margin: "1rem 0"}}>
      <h3 style={{fontFamily: "monospace"}} />
      <pre
        style={{
          background: "#f6f8fa",
          fontSize: ".65rem",
          padding: ".5rem",
        }}>
        <strong>props</strong> = {JSON.stringify(props, null, 2)}
      </pre>
    </div>
  );
