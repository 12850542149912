import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {useHttpClient} from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Card from "../../shared/components/UIElements/Card";
import * as Yup from "yup";
import {withFormik} from "formik";
import {DisplayFormikState, TextInput} from "../../shared/components/FormikElements/FormikElements";

import "./UserLogin.css";

import {AuthContext} from "../../shared/context/auth-context";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    userID: Yup.string().min(2, "Too Short!").required("Required"),
    password: Yup.string().required(),
  }),

  mapPropsToValues: ({user}) => ({
    ...user,
  }),
  handleSubmit: async (payload, {setSubmitting, resetForm, props}) => {
    try {
      console.log("My payload:" + JSON.stringify(payload));
      console.log("________________________");
      let result = await props.sendRequest(
        //        "https://ps.lpm.feri.um.si/api/register/login",
        `${process.env.REACT_APP_SRV_URL}/register/login`,
        "POST",
        JSON.stringify(payload),
        {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }
      );
      console.log("result:" + JSON.stringify(result));
      resetForm();
      props.auth.login(result.userID, result.token, result.role);
      props.history.push("/");
    } catch (err) {
      console.log("My ERROR " + err + " 33" + err.message);
    }
  },
  displayName: "MyForm Login",
});

const MyForm = (props) => {
  const {values, touched, errors, dirty, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting} = props;
  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <LoadingSpinner asOverlay />}
      <TextInput
        id="userID"
        type="text"
        label="userID"
        placeholder="Without spaces and special characters."
        error={touched.userID && errors.userID}
        value={values.userID}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="password"
        type="password"
        label="Password"
        placeholder=""
        error={touched.password && errors.password}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <button type="button" className="outline" onClick={handleReset} disabled={!dirty || isSubmitting}>
        Reset
      </button>
      <button type="submit" disabled={isSubmitting}>
        Submit
      </button>
      <DisplayFormikState {...props} />
    </form>
  );
};

const MyLoginForm = formikEnhancer(MyForm);

const UserLogin = () => {
  const {error, sendRequest, clearError} = useHttpClient();
  const auth = useContext(AuthContext);
  const history = useHistory();
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Card>
        <div>
          <h1 className="center">Insert credentals</h1>
          <MyLoginForm
            user={{
              userID: "admin",
              password: "admin55",
            }}
            sendRequest={sendRequest}
            auth={auth}
            history={history}
          />
        </div>
      </Card>
    </React.Fragment>
  );
};
export default UserLogin;
