import React, {useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useHttpClient} from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Card from "../../shared/components/UIElements/Card";
import * as Yup from "yup";
import {withFormik} from "formik";

import {DisplayFormikState, TextInput, TextAreaInput, SelectInput} from "../../shared/components/FormikElements/FormikElements";

import "./NewRoute.css";

import {AuthContext} from "../../shared/context/auth-context";
import Button from "../../shared/components/FormElements/Button";
const METHODS = ["GET", "POST", "PUT", "PATCH", "DELETE"];
const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    routeID: Yup.string().min(2).required(),
    description: Yup.string(),
    url: Yup.string().url(),
    request: Yup.string(),
    response: Yup.string(),
    type: Yup.string().oneOf(METHODS).required(),
  }),

  mapPropsToValues: ({data}) => ({
    ...data,
  }),
  handleSubmit: async (payload, {setSubmitting, resetForm, props}) => {
    try {
      console.log("insert:" + props.insert + " " + JSON.stringify(payload));
      let result = await props.sendRequest(
        props.insert
          ? `${process.env.REACT_APP_SRV_URL}/register/routes`
          : `${process.env.REACT_APP_SRV_URL}/register/routes/${payload.id}`,
        props.insert ? "POST" : "PUT",
        JSON.stringify(payload),
        {
          "Content-Type": "application/json",
          "x-api-token": props.auth.token,
        }
      );
      resetForm();

      props.history.push("/routes");
    } catch (err) {
      console.log("My ERROR " + err);
    }
  },
  displayName: "MyForm Routes",
});

const MyForm = (props) => {
  const {values, touched, errors, dirty, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, insert} = props;
  let selection = values.tags;
  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <LoadingSpinner asOverlay />}

      <TextInput
        id="routeID"
        type="text"
        label="*RouteID"
        placeholder="Without spaces and special characters."
        error={touched.routeID && errors.routeID}
        value={values.routeID}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={!insert}
      />

      <TextInput
        id="description"
        label="Description"
        placeholder="algDE"
        error={touched.description && errors.description}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="url"
        label="*URL"
        placeholder="http://sime.um.si/cs/ps"
        error={touched.url && errors.url}
        value={values.url}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextAreaInput
        id="request"
        label="Request"
        placeholder=""
        rows={3}
        error={touched.request && errors.request}
        value={values.request}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextAreaInput
        id="response"
        label="Response"
        placeholder=""
        rows={3}
        error={touched.response && errors.response}
        value={values.response}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <SelectInput
        id="type"
        type="select"
        label="REST type"
        options={METHODS}
        error={touched.type && errors.type}
        value={values.type}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Button type="button" className="outline" onClick={handleReset} insert={insert} disabled={!dirty || isSubmitting}>
        Reset
      </Button>
      <Button type="submit" disabled={isSubmitting}>
        Submit
      </Button>
      <Button
        type="button"
        className="outline"
        onClick={() => {
          props.history.push("/users");
        }}>
        Cancel
      </Button>
      <DisplayFormikState {...props} />
    </form>
  );
};

const MyUserForm = formikEnhancer(MyForm);

const NewUser = () => {
  console.log("New User open");
  const {error, sendRequest, clearError} = useHttpClient();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  let data;
  let insert;
  if (!location.state) console.log("No location state");
  if (location.state && location.state.data) {
    data = location.state.data;
    insert = false;
  } else {
    insert = true;
    data = {
      routeID: "",
      description: "",
      url: "https://ps.lpm.feri.um.si/api/register/info",
      request: "",
      response: "",
      type: METHODS[0],
      tags: ["NEKAJ", "PAŠE"],
    };
  }
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Card>
        <div>
          <h1 className="center">{insert ? "New route" : "Update route"}</h1>
          <MyUserForm data={data} sendRequest={sendRequest} auth={auth} history={history} insert={insert} />
        </div>
      </Card>
    </React.Fragment>
  );
};
export default NewUser;
