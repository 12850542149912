export const parseDate = (dt) => {
  try {
    if (!dt) return "N/A";
    const now = new Date(dt);
    return now.toLocaleString();
  } catch (err) {
    return "dateFormatERR" + err;
  }
};
export const isDev = () => {
  return process.env.NODE_ENV === "development";
};
export const stringify_object = (object, depth = 0, max_depth = 2) => {
  // change max_depth to see more levels, for a touch event, 2 is good
  if (depth > max_depth) return "Object";

  const obj = {};
  for (let key in object) {
    let value = object[key];
    if (value instanceof Node)
      // specify which properties you want to see from the node
      value = {id: value.id};
    else if (value instanceof Window) value = "Window";
    else if (value instanceof Object) value = stringify_object(value, depth + 1, max_depth);

    obj[key] = value;
  }

  return depth ? obj : JSON.stringify(obj);
};
