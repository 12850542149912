import React, {useEffect, useState, useContext} from "react";
import {useHistory} from "react-router-dom";
import {useTable} from "react-table";
import {AuthContext} from "../../shared/context/auth-context";
import {useHttpClient} from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Card from "../../shared/components/UIElements/Card";
import Button from "../../shared/components/FormElements/Button";
import Modal from "../../shared/components/UIElements/Modal";
import {parseDate} from "../../shared/util/Util";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import "./Tags.css";

const defaultPropGetter = () => ({});
function MyTable({
  columns,
  data,
  showDeleteWarningHandler,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const auth = useContext(AuthContext);
  let history = useHistory();
  // Use the state and functions returned from useTable to build your UI
  const handleEdit = (data) => {
    history.push("/tags/new", {data: data}); //pass object with route
  };
  const handleDelete = (row) => {
    showDeleteWarningHandler(row);
  };
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <Table {...getTableProps()} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow {...headerGroups[1].getHeaderGroupProps()}>
          {headerGroups[1].headers.map((column) => (
            <th className="TableHeader" scope="row" {...column.getHeaderProps()}>
              {column.render("Header")}
            </th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell, j) => {
                return (
                  <TableCell
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
                    ])}>
                    {cell.column.className === "dateTime" && parseDate(cell.value)}
                    {cell.column.className !== "link" && cell.column.className !== "dateTime" && cell.render("Cell")}
                    {cell.column.className === "link" && cell.value !== "" && <a href={cell.value}>More</a>}
                    {cell.column.className === "Action" && auth.canEditDelete(data[i].userID) && (
                      <React.Fragment>
                        <button className={"button--edit button--small"} onClick={() => handleEdit(data[i])}>
                          <EditIcon fontSize="small" />
                        </button>
                        <button className={"button--danger button--small"} onClick={() => handleDelete(data[i].tagID)}>
                          <DeleteIcon fontSize="small" />
                        </button>
                      </React.Fragment>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const Tags = () => {
  const {isSubmitting, error, sendRequest, clearError} = useHttpClient();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const columns = React.useMemo(
    () => [
      {
        Header: "Tag list",
        columns: [
          {
            Header: "Action",
            accessor: "Action",
            className: "Action",
          },
          {
            Header: "Author",
            accessor: "userID",
            className: "userID",
            style: {
              fontWeight: "bolder",
            },
          },
          {
            Header: "Tag",
            accessor: "tagID",
            className: "tagID",
            style: {
              color: "red",
              fontWeight: "bolder",
            },
          },

          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Link",
            accessor: "link",
            className: "link",
          },
          {
            Header: "Modified",
            accessor: "modified",
            className: "dateTime",
          },
        ],
      },
    ],
    ["SA"]
  );
  const [data, setData] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [reload, setReload] = useState(0);
  const [row, setRow] = useState("");
  const showDeleteWarningHandler = (row) => {
    setShowConfirmModal(true);
    setRow(row);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  const confirmDeleteHandler = async () => {
    console.log("DELETE " + row);
    try {
      await sendRequest(`${process.env.REACT_APP_SRV_URL}/register/routes/tags/${row}`, "DELETE", null, {
        "Content-Type": "application/json",
        "x-api-token": auth.token,
      });
      setReload(() => {
        return reload + 1;
      });
      setShowConfirmModal(false);
    } catch (err) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("fetchData");
        if (auth.isLoggedIn) {
          const responseData = await sendRequest(process.env.REACT_APP_SRV_URL + "/register/routes/tags", "GET", null, {
            "x-api-token": auth.token,
          });
          setData(responseData);
        } else {
          console.log("Token not set");
        }
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, auth.isLoggedIn, reload]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Are you sure?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              CANCEL
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              DELETE
            </Button>
          </React.Fragment>
        }>
        <p>
          Do you want to proceed and delete tag <b>{row}</b>?
        </p>
      </Modal>

      {auth.isLoggedIn && (
        <React.Fragment>
          <Card>
            <Button disabled={!auth.canInsert()} to="/tags/new">
              NEW TAG
            </Button>
          </Card>

          <Card>
            {isSubmitting && (
              <div className="center">
                <LoadingSpinner />
              </div>
            )}
            {!isSubmitting && data && (
              <React.Fragment>
                <MyTable columns={columns} data={data} showDeleteWarningHandler={showDeleteWarningHandler} />
              </React.Fragment>
            )}
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default Tags;
