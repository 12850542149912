import React, {useEffect, useState, useContext} from "react";
import {useHistory} from "react-router-dom";
import {useTable} from "react-table";
import {AuthContext} from "../../shared/context/auth-context";
import {useHttpClient} from "../../shared/hooks/http-hook";
import Tag from "../../shared/components/UIElements/Tag";
import Button from "../../shared/components/FormElements/Button";
import Modal from "../../shared/components/UIElements/Modal";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {parseDate} from "../../shared/util/Util";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./RoutesTable.css";

const {REQUEST_TAG_TYPE, RESPONSE_TAG_TYPE} = require("../../shared/util/Constants");

const defaultPropGetter = () => ({});

function RoutesTable({
  data,
  showDeleteWarningHandler,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  setData,
  setShowConfirmModal,
  setReload,
  reload,
  ALL_TAGS,
}) {
  const getTagColor = (tagID) => {
    if (ALL_TAGS.find((x) => x.tagID === tagID).color) return ALL_TAGS.find((x) => x.tagID === tagID).color;
    return "red";
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "List",
        columns: [
          {
            Header: "Action",
            accessor: "Action",
            className: "Action",
          },
          {
            Header: "Owner",
            accessor: "ownerUserID",
          },
          ,
          {
            Header: "RouteID",
            accessor: "routeID",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "url",
            accessor: "url",
          },
          {
            Header: "request",
            accessor: "request",
          },
          {
            Header: "response",
            accessor: "response",
          },
          {
            Header: "type",
            accessor: "type",
          },
          {
            Header: "Modified",
            accessor: "modified",
            className: "dateTime",
          },
        ],
      },
    ],
    []
  );

  const auth = useContext(AuthContext);
  const {sendRequest, error, clearError} = useHttpClient();
  const [isTagInsert, setTagInsert] = useState(false);
  const [tagRowPos, setTagRowPos] = useState(0);
  const [tagInsertType, setTagInsertType] = useState(REQUEST_TAG_TYPE);
  const [selectedTag, setSelectedTag] = useState();

  const cancelTagInsert = () => {
    setTagInsert(false);
  };
  const startTagInsert = (i, type) => {
    setSelectedTag();
    setTagRowPos(i);
    setTagInsert(true);
    setTagInsertType(type);
  };
  const tagRoute = (type) => {
    if (type === REQUEST_TAG_TYPE) return "request";
    return "response";
  };
  const addTag = async (v, type) => {
    try {
      //setShowConfirmModal(true);
      await sendRequest(
        `${process.env.REACT_APP_SRV_URL}/register/routes/${data[tagRowPos].routeID}/${tagRoute(type)}/tags`,
        "POST",
        JSON.stringify({tagID: selectedTag.tagID}),
        {
          "Content-Type": "application/json",
          "x-api-token": auth.token,
        }
      ).then(() => {
        data[tagRowPos].TAGS[type].push(selectedTag);

        setShowConfirmModal(false);
        setReload(() => {
          return reload + 1;
        });
      });
    } catch (err) {}

    setTagInsert(false);
  };

  let history = useHistory();
  // Use the state and functions returned from useTable to build your UI
  const handleEdit = (data) => {
    history.push("/routes/new", {data: data}); //pass object with route
  };
  const handleDelete = (row) => {
    showDeleteWarningHandler(row);
  };
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data,
  });
  const deleteTag = async (i, ii, type) => {
    console.log("Delete" + i + "," + ii);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_SRV_URL}/register/routes/${data[i].routeID}/${tagRoute(type)}/tags/${data[i].TAGS[type][ii].tagID}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
          "x-api-token": auth.token,
        }
      );
      data[i].TAGS[type].splice(ii, 1);
      setData(data);
      setReload(() => {
        return reload + 1;
      });
    } catch (err) {}
  };
  const returnSelectedRowInfo = (i) => {
    console.log(i + " " + JSON.stringify(data[tagRowPos]));
    if (tagRowPos) return data[tagRowPos].routeID;
    return data[0].routeID; //BUG first eval (0) is undefined but it prints 0
  };
  // Render the UI for your table
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Table {...getTableProps()} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow {...headerGroups[1].getHeaderGroupProps()}>
            {headerGroups[1].headers.map((column) => (
              <th className="TableHeader" scope="row" {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <TableRow {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell, j) => {
                    return (
                      <TableCell
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                          getColumnProps(cell.column),
                          getCellProps(cell),
                        ])}>
                        {cell.column.className === "dateTime" && parseDate(cell.value)}
                        {cell.column.className !== "dateTime" && cell.render("Cell")}
                        {cell.column.className === "Action" && auth.canEditDelete(data[i].ownerUserID) && (
                          <React.Fragment>
                            <button className={"button--edit button--small"} onClick={() => handleEdit(data[i])}>
                              <EditIcon fontSize="small" />
                            </button>
                            <button className={"button--danger button--small"} onClick={() => handleDelete(data[i].id)}>
                              <DeleteIcon fontSize="small" />
                            </button>
                          </React.Fragment>
                        )}
                        {cell.column.className === "Action" && !auth.canEditDelete(data[i].ownerUserID) && (
                          <React.Fragment>
                            <button disabled className={"button--edit button--small"}>
                              <EditIcon fontSize="small" />
                            </button>
                            <button disabled className={"button--danger button--small"}>
                              <DeleteIcon fontSize="small" />
                            </button>
                          </React.Fragment>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow className="requestrow">
                  <TableCell>Request TAGS</TableCell>
                  <TableCell colSpan={row.cells.length - 1}>
                    {auth.canEditDelete(data[i].ownerUserID) && (
                      <button margine-top="10px" className={"button button--small"} onClick={() => startTagInsert(i, REQUEST_TAG_TYPE)}>
                        <AddIcon fontSize="small" />
                      </button>
                    )}
                    {data[i].TAGS[REQUEST_TAG_TYPE].map((tag, ii) => {
                      return (
                        <React.Fragment key={ii}>
                          {auth.canEditDelete(data[i].ownerUserID) && (
                            <Tag label={tag.tagID} color={getTagColor(tag.tagID)} onDelete={() => deleteTag(i, ii, REQUEST_TAG_TYPE)} />
                          )}
                          {!auth.canEditDelete(data[i].ownerUserID) && <Tag color={getTagColor(tag.tagID)} label={tag.tagID} />}
                        </React.Fragment>
                      );
                    })}
                  </TableCell>
                </TableRow>
                <TableRow className="responserow">
                  <TableCell>Response TAGS</TableCell>
                  <TableCell colSpan={row.cells.length - 1}>
                    {auth.canEditDelete(data[i].ownerUserID) && (
                      <button margine-top="10px" className={"button button--small"} onClick={() => startTagInsert(i, RESPONSE_TAG_TYPE)}>
                        <AddIcon fontSize="small" />
                      </button>
                    )}
                    {data[i].TAGS[RESPONSE_TAG_TYPE].map((tag, ii) => {
                      return (
                        <React.Fragment key={ii}>
                          {auth.canEditDelete(data[i].ownerUserID) && (
                            <Tag color={getTagColor(tag.tagID)} label={tag.tagID} onDelete={() => deleteTag(i, ii, RESPONSE_TAG_TYPE)} />
                          )}
                          {!auth.canEditDelete(data[i].ownerUserID) && <Tag color={getTagColor(tag.tagID)} label={tag.tagID} />}
                        </React.Fragment>
                      );
                    })}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
      {isTagInsert && (
        <Modal
          show={isTagInsert}
          onCancel={cancelTagInsert}
          header={`Add new tag for ${returnSelectedRowInfo()}`}
          footerClass="place-item__modal-actions"
          footer={
            <React.Fragment>
              <Button inverse onClick={cancelTagInsert}>
                CANCEL
              </Button>
              <Button onClick={() => addTag(tagRowPos, tagInsertType)}>ADD TAG</Button>
            </React.Fragment>
          }>
          <p>{`Insert data for selectes row?${tagRowPos}${returnSelectedRowInfo()}`}</p>

          <Autocomplete
            options={ALL_TAGS}
            getOptionLabel={(option) => option.tagID}
            style={{width: "100%"}}
            disableClearable
            onChange={(e, v) => setSelectedTag(v)}
            renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
          />
          {selectedTag && <label>{`Selected tag: ${selectedTag.tagID} description: ${selectedTag.description}`}</label>}
        </Modal>
      )}
    </React.Fragment>
  );
}

export default RoutesTable;
